<template>
  <div>
    <div class="row">
      <div class="col-6">
        <CardsStats></CardsStats>
      </div>
    </div>
    <div class="row">
      <b-card title="Volume de cartelas por dia" class="col-6">
        <div id="main" v-if="cardsCollection">
          <line-chart :chart-data="cardsCollection" :options="this.options" :styles="lineChartCss"></line-chart>
        </div>
      </b-card>
      <b-card title="Frequencia de usuários por dia" class="col-6">
        <div id="main" v-if="cardsCollection">
          <line-chart :chart-data="usersCollection" :options="this.options" :styles="lineChartCss"></line-chart>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink, BBadge, BCardHeader, BCardTitle, BCardBody, BCardSubTitle } from 'bootstrap-vue'
import LineChart from './charts/LineChart.js'
import CardsStats from './cards/CardsStats.vue'
export default {
  components: {
    CardsStats,
    LineChart,
    BCard,
    BBadge,
    BCardText,
    BCardHeader,
    BLink,
    BCardTitle, BCardBody, BCardSubTitle,
  },
  data() {
    return {
      data:false,
      cardsCollection: null,
      cardsVolume:null,
      usersCollection: null,
      usersVolume:null,
      lineChartCss:{
        height: "350px",
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          enabled: true
        }
      }
    }
  },
  mounted(){
     this.getCardsVolume();
     this.getUsersVolume();
  },
  methods:{
    getCardsVolume(){
      this.$http.get('/data/cards-volume')
     .then(res => {
        this.data = res.data;
        this.cardsVolumeLabels = this.data.map(el=>el.x);
        this.cardsVolumeData = this.data.map(el=>el.y);
        this.cardsCollection = {
          labels: this.cardsVolumeLabels,
          datasets: [
          {
              label: 'Volume de cartelas',
              data: this.cardsVolumeData,
              borderWidth: 3, 
              borderColor: "rgb(40, 199, 111)", 
              fill:false,
              pointBackgroundColor:"#fff"
            }
          ]
        }
      })
    },
    getUsersVolume(){
      this.$http.get('/data/users-frequency')
     .then(res => {
        this.data = res.data;
        this.usersVolumeLabels = this.data.map(el=>el.date);
        this.usersVolumeData = this.data.map(el=>el.users);
        this.usersCollection = {
          labels: this.usersVolumeLabels,
          datasets: [
            {
              label: 'Volume de usuários',
              data: this.usersVolumeData,
              borderWidth: 3, 
              borderColor: "rgb(40, 199, 111)", 
              fill:false,
              pointBackgroundColor:"#fff"
            }
          ]
        }
      })
    }
  }
}


</script>

<style>

</style>
